<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    @close="close"
    width="570px"
  >
    <el-form
      ref="form"
      :inline="true"
      :model="form"
      :rules="roles"
      size="small"
      label-width="88px"
    >
      <el-form-item label="菜单标题" prop="name">
        <el-input style="width: 420px" v-model="form.name" />
      </el-form-item>
      <el-form-item label="上级部门" prop="parentId">
        <treeselect
          v-model="form.parentId"
          :options="tableData"
          style="width: 420px"
          placeholder="选择部门"
          :normalizer="normalizer"
        />
      </el-form-item>
      <el-form-item label="路由地址" prop="url">
        <el-input style="width: 420px" v-model="form.url" />
      </el-form-item>
      <el-form-item label="菜单图标" prop="icon">
        <el-popover
          placement="bottom-start"
          width="450"
          trigger="click"
          @show="$refs['iconSelect'].reset()"
        >
          <IconSelect ref="iconSelect" @selected="selected" />
          <el-input
            slot="reference"
            v-model="form.icon"
            style="width: 420px"
            placeholder="点击选择图标"
            readonly
          >
            <svg-icon
              v-if="form.icon"
              slot="prefix"
              :icon-class="form.icon"
              class="el-input__icon"
              style="height: 32px; width: 16px"
            />
            <i v-else slot="prefix" class="el-icon-search el-input__icon" />
          </el-input>
        </el-popover>
      </el-form-item>
      <el-form-item label="菜单排序" prop="orderNum">
        <el-input type="number" style="width: 420px" v-model="form.orderNum" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="close">取消</el-button>
      <el-button type="primary" @click="submit()">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import IconSelect from "@/components/IconSelect";
export default {
  data() {
    return {
      dialogVisible: true,
      form: {
        name: "", //	string	true	普通参数		菜单名
        orderNum: "", //	string	true	普通参数		排序值
        parentId: null, //	string	true	普通参数		父菜单id parentId为null 表示一级菜单
        icon: "", //	string	true	普通参数		图标地址
        url: "", //	string	true	普通参数		访问地址
      },
      roles: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        orderNum: [
          { required: true, message: "请选择菜单排序", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    tableData: {
      default: [],
    },
    selectItem: {},
  },
  mounted() {
    if (JSON.stringify(this.selectItem) != "{}") {
      this.form = JSON.parse(JSON.stringify(this.selectItem));
    }
  },
  methods: {
    // 选中图标
    selected(name) {
      // console.log(name);
      this.form.icon = name;
    },
    normalizer(node) {
      //去掉children=[]的children属性
      if (!node.children || (node.children && !node.children.length)) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
        isDefaultExpanded: true,
      };
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.form);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
  components: { IconSelect, Treeselect },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
