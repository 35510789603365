import request from "@/utils/request";

// 接口：获取菜单树，所有菜单
// 地址：http://${BASE_URL}/user-service/sysMenu/tree
export function sysMenuTree() {
  return request({
    url: `/small-inteface/user-service/sysMenu/tree`,
    method: "get",
  });
}
// 接口：创建菜单 post
// 地址：http://${BASE_URL}/user-service/sysMenu
export function sysMenu(data) {
  return request({
    url: "/small-inteface/user-service/sysMenu",
    method: "post",
    data
  })
}
// 接口：修改菜单
// 地址：http://${BASE_URL}/user-service/sysMenu
export function sysMenuPut (data) {
  return request({
      url: '/small-inteface/user-service/sysMenu',
      method: 'put',
      data
  })
}
// 接口：根据id删除菜单
// 地址：http://${BASE_URL}/user-service/sysMenu/{id}
export function delLrHotWord (id) {
  return request({
      url: `/small-inteface/user-service/sysMenu/${id}`,
      method: 'delete'
  })
}
